import { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { Campaigns } from './components/campaigns'
import { About } from './components/about'
import { Pride } from './components/pride'
import { References } from './components/references'
import { Footer } from './components/footer'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Campaigns data={landingPageData.Campaigns} />
      <About data={landingPageData.About} />
      <Pride />
      <References data={landingPageData.References} />
      <Footer />
    </div>
  )
}

export default App
