export const Footer = (props) => {
  
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2023 LGBTQIACOM. Powered by{' '}
            <a href='https://commerce.coinbase.com/checkout/a85a9375-974f-4809-97e1-46912a3e5a45' rel='nofollow'>
              Xetawallet
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
